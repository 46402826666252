<template>
  <component :is="invoiceData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Faturamento não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Lista de notas fiscais
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <template v-if="invoiceData">
      <div>
        <!-- number -->
        <b-card>
          <h2 class="mb-2">
            {{ $t("message.invoices.viewInvoice") }}
          </h2>
          <b-row>
            <b-col md="8" class="mb-1">
              <b-form-group label="Numero" label-for="numberInvoices">
                <template v-slot:label>
                  {{ $t("message.invoices.number") }}
                </template>
                <b-form-input
                  v-model="invoiceData.number"
                  label="number"
                  label-for="nameInvoices"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="invoiceDate">
                <template v-slot:label>
                  {{ $t("message.invoices.revenues") }}
                </template>
                <b-form-datepicker
                  id="created-date"
                  v-model="invoiceData.invoiceDate"
                  class="form-control"
                  :locale="$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="invoiceExpiryDate">
                <template v-slot:label>
                  {{ $t("message.invoices.Duedate") }}
                </template>
                <b-form-datepicker
                  id="created-date"
                  v-model="invoiceData.invoiceExpiryDate"
                  class="form-control"
                  :locale="$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="customerInvoices">
                <template v-slot:label>
                  {{ $t("message.invoices.costumer") }}
                </template>

                <v-select
                  v-model="invoiceData.customerId"
                  disabled
                  :options="customerOptions"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="contract">
                <template v-slot:label>
                  {{ $t("message.invoices.Contract") }}
                </template>
                <v-select
                  v-model="invoiceData.contractId"
                  disabled
                  :options="contractOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group label-for="value">
                <template v-slot:label>
                  {{ $t("message.invoices.value") }}
                </template>
                <b-form-input
                  label="value"
                  v-money="getCurrencyFormat()"
                  disabled
                  v-model="invoiceData.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="contractInstallment">
                <template v-slot:label>
                  {{ $t("message.invoices.installments") }}
                </template>
                <b-form-input v-model="installments" disabled />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="statusInvoices">
                <template v-slot:label>
                  {{ $t("message.invoices.states") }}
                </template>
                <v-select
                  v-model="invoiceData.status"
                  disabled
                  :options="status"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="invoiceData.taxes">
              <br />
              <h3>{{ $t("tax") }}</h3>
              <br />
              <div
                class="form-group"
                v-for="(input, k) in invoiceData.taxes"
                :key="k"
              >
                <b-row>
                  <b-col md="3">
                    {{ $t("taxName") }}
                    <b-form-input v-model="input.name" disabled />
                  </b-col>
                  <b-col md="2">
                    {{ $t("taxValue") }}
                    <b-form-input
                      v-model="input.value"
                      disabled
                      v-money="getCurrencyFormat()"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-10">
            <b-col>
              <div class="text-right mt-10 mr-2 mb-2">
                <b-button
                  @click="
                    $router.push({
                      name: 'apps-invoices-edit',
                      params: { id: invoiceData.id },
                    })
                  "
                  variant="primary"
                  class="mt-5"
                  v-if="restrictions('button_edit_invoice')"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1 mt-5"
                  v-if="restrictions('button_delete_invoice')"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          Você tem certeza que deseja deletar a conta ?
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-invoice-list' }"
            @click="deleteInvoice()"
          >
            Deletar
          </b-button>
        </div>
      </b-modal>
    </template>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import AccessControl from "@core/utils/access-control";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    flatPickr,
    BFormDatepicker,
    BCardText,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      invoiceData: [],

      customerOptions: [],
      contractOptions: [],

      status: [
        { label: "Aberto", value: "Aberto" },
        { label: "Fechado", value: "Fechado" },
      ],

      installments: "",
      userData: store.state.user.userData,
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    deleteInvoice() {
      axios({
        method: "delete",
        url: `${URL_API}invoice/${this.invoiceData.id}`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.deleted"),
            icon: "CheckIcon",
            variant: "success",
            text: `A nota foi deletada com sucesso`,
          },
        });
      });
    },
  },

  async created() {
    await axios({
      method: "get",
      url: `${URL_API}invoice/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    })
      .then((response) => {
        this.invoiceData = response.data;
        this.installments = response.data.installment.join(",");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.invoiceData = undefined;
        }
      });

    var customerList = [];
    var customerId = [];
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });

    var contractList = [];
    var contractId = [];
    await axios
      .get(`${URL_API}contract/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          contractList.push(element);
          contractId.push(id);
          this.contractOptions.push({
            label: contractList[i],
            value: contractId[i],
          });
        }
      });
  },
};
</script>
